<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Titulo -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Titulo"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Descrição -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                :state="errors.length > 0 ? false : null"
                rows="3"
                max-rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- DAta -->
          <validation-provider
            v-slot="{ errors }"
            name="date"
            rules="required"
          >
            <b-form-group
              label="Data"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                v-model="form.date"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- inicio do tempo -->
          <validation-provider
            v-slot="{ errors }"
            name="start_time"
            rules="required"
          >
            <b-form-group
              label="Inicio"
              label-for="start_time"
            >
              <b-form-timepicker
                v-model="form.start_time"
                locale="pt-br"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Fim do tempo -->
          <validation-provider
            v-slot="{ errors }"
            name="end_time"
            rules="required"
          >
            <b-form-group
              label="Fim"
              label-for="end_time"
            >
              <b-form-timepicker
                v-model="form.end_time"
                locale="pt-br"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- tIPO -->
          <validation-provider
            v-slot="{ errors }"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Tipo"
              label-for="type"
            >
              <v-select
                id="type "
                v-model="form.type"
                :options="typeEvent"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Tema -->
          <validation-provider
            v-slot="{ errors }"
            name="theme"
            rules="required"
          >
            <b-form-group
              label="Tema"
              label-for="theme"
            >
              <b-form-input
                id="theme"
                v-model="form.theme"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Sala -->
          <validation-provider
            v-slot="{ errors }"
            name="room"
            rules="required"
          >
            <b-form-group
              label="Sala"
              label-for="room"
            >
              <v-select
                id="room"
                v-model="form.room"
                :options="room"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Link -->
          <validation-provider
            v-slot="{ errors }"
            name="link"
            rules="required"
          >
            <b-form-group
              label="Link"
              label-for="link"
            >
              <b-form-input
                id="link"
                v-model="form.link"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Tipo do Link -->
          <validation-provider
            v-slot="{ errors }"
            name="type_link"
            rules="required"
          >
            <b-form-group
              label="Tipo do link"
              label-for="type_link"
            >
              <v-select
                id="type_link"
                v-model="form.type_link"
                :options="type_link"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip, BFormTextarea, BFormTimepicker
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import countries from '@/@fake-db/data/other/countries'
import { mapActions } from 'vuex'
import { integer } from 'vee-validate/dist/rules'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      speaker_id: null,
      event_id: null,
      boolean: ['Sim', 'Não'],
      typeEvent: ['Palestrante', 'Mesa redonda'],
      room: ['Sala 01', 'Sala 02'],
      type_link: ['Youtube', 'Cloudflare'],
      events: [],
      speakers: [],
      form: {
        title: null,
        description: null,
        start_time: null,
        end_time: null,
        type: null,
        theme: null,
        date: null,
        room: null,
        link: null,
        type_link: null,
      }
    }
  },
  computed: {
    eventsState() {
      return this.$store.state.congressAdministrator.events
    },
    speakersState() {
      return this.$store.state.congressAdministrator.speakers
    }
  },
  watch: {
    event_id() {
      this.form.event_id = this.event_id.value
    },
    speaker_id() {
      this.form.speaker_id = this.speaker_id.value
    },
    eventsState() {
      // get date key
      Object.keys(this.eventsState).forEach(date => {
        // get room key
        Object.keys(this.eventsState[date]).forEach(room => {
          // get event objec
          this.eventsState[date][room].forEach(event => {
            const eventDate = new Date(`${event.date} ${event.start_time}`)
            this.events.push({
              value: event.id,
              text: `${event.title}`,
              date: eventDate
            })
            this.events.sort(this.sortByDate)
          })
        })
      })
    },
    speakersState() {
      this.speakers = []
      this.speakersState.forEach(element => {
        this.speakers.push({
          value: element[0].id,
          text: element[0].name_badge
        })
        this.speakers.sort(this.sortByName)
      })
    },
  },
  created() {
    this.ActionListAllEvents({ slug: this.$route.params.slug })
    this.ActionListAllSpeakers()
  },
  methods: {
    resetForm() {
      this.form = {
        event_id: null,
        speaker_id: null,
        password_vmix: null
      }
    },
    ...mapActions('congressAdministrator', [
      'ActionAddSpeakerToEvent',
      'ActionListAllEvents',
      'ActionListAllSpeakers'
    ]),
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const response = await this.ActionAddSpeakerToEvent(this.form)

            if (response.data.status === 400) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: response.data.message,
                  text: ` ERROR: ${response.data.errors[0][0]} #${response.data.errors[0][1]}`,
                  variant: 'danger',
                }
              })
            } else if (response.data.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'InfoIcon',
                  variant: 'success',
                }
              })
              this.$emit('close')
            }
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
